import { setFilter, loadResponce } from './actions';
import request from '../../utils/request';
import lodash from 'lodash';
import { setRedirected, fetchCabinets, fetchStaff, handleError } from '../legacyState';
import { setField } from '../selections/doctor/actions';

const _ = lodash;

export function fetchSchedule() {
  return async (dispatch, getState) => {
    try {
      await dispatch(fetchStaff());
      await dispatch(fetchCabinets());
      const { common } = getState();
      let response = await request.clinic.get_schedule(common.user.clinic);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      dispatch(loadResponce(response.data.items));
    } catch (error) {
      handleError(error);
    }
  };
}

export function initFilter(page = 'appointments') {
  return async (dispatch, getState) => {
    try {
      await dispatch(fetchStaff());
      await dispatch(fetchCabinets());
      const { clinic, common } = getState();
      const me = clinic.staff.filter((item) => item.id === common.user.id);
      const doctor = common.redirected ? me.map((item) => item.id) : clinic.staff.map((item) => item.id);
      const job =
        page === 'tasks'
          ? _.uniq(clinic.staff.map((item) => item.job))
          : _.uniq(clinic.staff.filter((staffer) => staffer.role === 'DOC').map((item) => item.job));
      const cabinet = clinic.cabinets.map((item) => item.id);
      await dispatch(
        setFilter({
          doctor,
          cabinet,
          job,
          day: new Date(),
        }),
      );

      common.redirected && (await dispatch(setField(`${me[0].last_name} ${me[0].first_name} ${me[0].second_name}`)));
      await dispatch(setRedirected(false));
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
}
